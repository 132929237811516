<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<b-alert
			variant="warning"
			show>
			<b-row>
				<b-col
					:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
					class="col-12">
					<h5
						:class="!['xs', 'sm'].includes(windowWidth) ? 'float-left' : ''"
						v-html="translate('customer_becomes_affiliate')" />
				</b-col>
			</b-row>
			<b-row>
				<b-col class="col-12 text-center">
					<button
						class="btn btn-primary mx-2"
						@click="customerBecomesAffiliate">
						{{ translate('yes') }}
					</button>
					<button
						class="btn btn-secondary mx-2"
						@click="$router.push({ name: 'Home' })">
						{{ translate('no_word') }}
					</button>
				</b-col>
			</b-row>
		</b-alert>
	</div>
</template>

<script>
import User from '@/util/User';
import { Users } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'UpgradeCustomerToAffiliate',
	messages: [Users],
	mixins: [WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			user: new User(),
		};
	},
	methods: {
		customerBecomesAffiliate() {
			const trans = {
				title: this.translate('upgrade_to_affiliate'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};

			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					password: password.value,
				};
				this.user.becomeAffiliate(payload).then(() => {
					try {
						this.alert.toast('success', this.translate('account_upgraded'));
					} finally {
						this.$router.push({ name: 'Home' }, () => this.$router.go(0));
					}
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.user.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
	},
};
</script>
